<template>
  <div class="item">
    <InputText
      ref="button"
      v-model="model"
      :label="t('common.searchfor')"
      :placeholder="placeholder"
      icon="ion:search"
      @input="handleInput"
      @submit="handleSubmit"
    />

    <PageheaderSearchItemTab
      ref="tab"
      :content="{ icon: 'ion:search', text: t('common.search') }"
      :active="showDropdown"
      @click="handleClick"
    />

    <PageheaderSearchItemDropdown
      v-if="showDropdown"
      v-click-outside="{
        ignore: [button, tab],
        handler: () => handleClickOutside(),
      }"
    >
      <PageheaderSearchItemDropdownTerm
        v-model="model"
        @input="handleSubmit($event)"
      />
    </PageheaderSearchItemDropdown>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();

const emit = defineEmits(['activate', 'close', 'deactivate', 'submit']);

const props = defineProps<{
  itemId: string;
  activeItem: string;
  placeholder: string;
}>();

const model = ref<string>('');

const button = ref<HTMLElement | null>(null);
const tab = ref<HTMLElement | null>(null);

const showDropdown = computed(() => {
  return props.activeItem === props.itemId;
});

const handleClick = () => {
  if (showDropdown.value) {
    emit('close');
  } else {
    emit('activate', props.itemId);
  }
};

const handleInput = () => {
  if (!showDropdown.value) {
    emit('activate', props.itemId);
  }
};

const handleSubmit = (value: string) => {
  model.value = value;
  emit('submit', model.value);
};

const handleClickOutside = () => {
  model.value = '';
  emit('deactivate', props.itemId);
};
</script>

<style src="./Item.scss" scoped lang="scss"></style>
