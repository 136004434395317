<template>
  <div class="term">
    <!-- this text input is linked to the input in the pageHeader-search -->
    <!-- use v-if showInput here, because opened dropdown is cancelling submit on Item-Term Input-Text, because of this Input-Text -->
    <div v-if="showInput" class="input">
      <InputText
        v-model="model"
        :label="
          t('components.pageheader.search.item.dropdown.term.input-text.label')
        "
        :placeholder="
          t(
            'components.pageheader.search.item.dropdown.term.input-text.placeholder'
          )
        "
        icon="ion:search"
        @submit="emit('input', $event)"
      />
    </div>

    <!-- this list contains actual autocomplete like suggestions that are based on the user input -->
    <div class="autocomplete">
      <PageheaderSearchItemDropdownTermList
        :content="autocomplete"
        @input="emit('input', $event)"
      />
    </div>

    <!-- those are the prefilled lists that show up as long as the user did not provide any input -->
    <!-- <div v-if="!isEmpty(suggestions)" class="suggestions">
      <PageheaderSearchItemDropdownTermList
        v-for="list in suggestions"
        :content="list"
        @input="emit('input', $event)"
      />
    </div> -->

    <!-- those teaser items are also only shown as long the user did not provide any input -->
    <!-- <div v-if="!isEmpty(teaser)" class="teaser">
      <TeaserFilter v-for="item in teaser" :content="item" />
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import type { List } from './List/models';
import { MediaType } from '@assets/scss/variables';
import isEmpty from '@utils/isEmpty';
import { WhlModuleType } from '@models/WhlModuleType';
import type { RawEventSearchAutosuggestItemFragment } from '@gql/fragments/__generated/RawEventSearchAutosuggestItem';
import type { RawAddressbaseSearchAutosuggestItemFragment } from '@gql/fragments/__generated/RawAddressbaseSearchAutosuggestItem';
import { useGlobalStore } from '@stores/globalStore';

const { t, locale } = useI18n();

const DEBOUNCE_DELAY = 500;

const emit = defineEmits(['input']);

const widgetConfig = await useWidgetConfig();
const searchStore = useSearchStore();
const whlModuleType = useWhlModuleType();

const model = defineModel<string>({
  default: '',
});

/* const suggestions = ref<List[]>([
    {
    title: 'Häufig gesuchte Touren',
    list: [
      {
        icon: 'ion:location-sharp',
        text: 'Münsterplatz',
      },
      {
        icon: 'ion:location-sharp',
        text: 'Theater Freiburg',
      },
      {
        icon: 'ion:location-sharp',
        text: 'Augustinermuseum Freiburg',
      },
      {
        icon: 'ion:location-sharp',
        text: 'Colombischlössle - Archäologisches Museum',
      },
    ],
  },
  {
    title: 'Häufig gesuchte Veranstaltungen',
    list: [
      {
        icon: 'ion:calendar-outline',
        text: 'Öffentlicher Stadtrundgang Freiburg',
      },
      {
        icon: 'ion:calendar-outline',
        text: 'Freiburger Weinsommer',
      },
      {
        icon: 'ion:calendar-outline',
        text: 'Ferienprogramm - Wilde Experimente',
      },
      {
        icon: 'ion:calendar-outline',
        text: 'Konzerte im Freien',
      },
    ],
  },
]); */

/* const teaser = ref<TeaserFilter[]>([
  // {
  //   fig: {
  //     deeplink: 'https://picsum.photos/id/65/453/347',
  //     pooledMedium: {
  //       title: 'placeholder',
  //       altText: 'placeholder',
  //       copyright: 'Alexander Shustov',
  //     },
  //   },
  //   title: 'Augustinermuseum Freiburg',
  // },
  // {
  //   fig: {
  //     deeplink: 'https://picsum.photos/id/65/453/347',
  //     pooledMedium: {
  //       title: 'placeholder',
  //       altText: 'placeholder',
  //       copyright: 'Alexander Shustov',
  //     },
  //   },
  //   title: 'Augustinermuseum Freiburg',
  // },
]); */

const globalStore = useGlobalStore();
const showInput = computed(() => globalStore.state.mediaType === MediaType.TY);

const autocomplete = ref<List>({
  title: t(
    'components.pageheader.search.item.dropdown.term.autocomplete.title'
  ),
  list: [],
});

// Initialize search for events
const {
  events,
  resume: resumeEvents,
  pause: pauseEvents,
} = fetchEventsAutosuggest(
  locale,
  widgetConfig,
  searchStore.eventUserFilter,
  buildEventBaseFilter(widgetConfig),
  model,
  true
);

// Initialize search for POIs
const {
  pois,
  resume: resumePois,
  pause: pausePois,
} = fetchPoisAutosuggest(
  locale,
  widgetConfig,
  searchStore.poiUserFilter,
  buildPoiBaseFilter(widgetConfig),
  model,
  true
);

// Initialize search for tours
const {
  pois: tours,
  resume: resumeTours,
  pause: pauseTours,
} = fetchPoisAutosuggest(
  locale,
  widgetConfig,
  searchStore.tourUserFilter,
  buildTourBaseFilter(widgetConfig),
  model,
  true
);

// Helper function to determine if search query should be paused
const shouldPauseSearch = (moduleType: WhlModuleType) => {
  // Create a debounced model value that updates 1 second after changes
  const debouncedModel = ref(model.value);
  let debounceTimer: ReturnType<typeof setTimeout> | null = null;

  // Watch for changes to model.value and update debouncedModel
  watch(model, (newValue) => {
    // Clear any existing timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new timer
    debounceTimer = setTimeout(() => {
      debouncedModel.value = newValue;
    }, DEBOUNCE_DELAY);
  });

  // Return computed property using the debounced model value
  return computed(
    () =>
      isEmpty(debouncedModel.value) ||
      debouncedModel.value.length < 3 ||
      whlModuleType.value !== moduleType
  );
};

// Create computed properties for each module type
const shouldPauseEventsFetching = shouldPauseSearch(WhlModuleType.EVENT);
const shouldPausePoisFetching = shouldPauseSearch(WhlModuleType.POI);
const shouldPauseToursFetching = shouldPauseSearch(WhlModuleType.TOUR);

// Helper function to create watchers for pausing/resuming searches with debounce
const createSearchWatcher = (
  shouldPause: Ref<boolean>,
  pauseFn: () => void,
  resumeFn: () => void
) => {
  watch(shouldPause, (newVal) => {
    if (newVal) {
      pauseFn();
    } else {
      resumeFn();
    }
  });
};

// Create watchers for each search type
createSearchWatcher(shouldPauseEventsFetching, pauseEvents, resumeEvents);
createSearchWatcher(shouldPausePoisFetching, pausePois, resumePois);
createSearchWatcher(shouldPauseToursFetching, pauseTours, resumeTours);

const icon = computed(() => {
  switch (whlModuleType.value) {
    case WhlModuleType.EVENT:
      return 'ion:calendar-outline';
    case WhlModuleType.TOUR:
      return 'ion:location-sharp';
    case WhlModuleType.POI:
      return 'ion:search';
    default:
      return '';
  }
});

watch([pois, events, tours], () => {
  if (!pois.value && !events.value && !tours.value) return;

  const mapItemToAutocomplete = (
    item:
      | RawEventSearchAutosuggestItemFragment
      | RawAddressbaseSearchAutosuggestItemFragment
  ) => {
    const title = item.title || '';

    // If there's user input and it's found in the title, wrap it with highlight span
    if (
      !isEmpty(model) &&
      title.toLowerCase().includes(model.value.toLowerCase())
    ) {
      const regex = new RegExp(`(${model.value})`, 'gi');
      return {
        icon: icon.value,
        text: title.replace(regex, '<b>$1</b>'),
      };
    }

    return {
      icon: icon.value,
      text: title,
    };
  };

  if (!isEmpty(pois.value)) {
    autocomplete.value.list = pois.value.map(mapItemToAutocomplete);
  } else if (!isEmpty(events.value)) {
    autocomplete.value.list = events.value.map(mapItemToAutocomplete);
  } else if (!isEmpty(tours.value)) {
    autocomplete.value.list = tours.value.map(mapItemToAutocomplete);
  }
});
</script>

<style src="./Term.scss" scoped lang="scss"></style>
