import { useQuery } from '@urql/vue';
import type { MaybeRef, Ref } from 'vue';
import type { EventFilter } from '@gql/schema';
import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { RawAddressbaseSearchAutosuggestItemFragment } from '@gql/fragments/__generated/RawAddressbaseSearchAutosuggestItem';
import { AddressbaseSearchAutosuggestDocument } from '@gql/queries/__generated/AddressbaseSearchAutosuggest';
import type {
  AddressbaseSearchAutosuggestQuery,
  AddressbaseSearchAutosuggestQueryVariables,
} from '@gql/queries/__generated/AddressbaseSearchAutosuggest';

export default (
  locale: MaybeRef<string> = ref<string>(''),
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  userFilter: MaybeRef<EventFilter> = ref<EventFilter>({}),
  baseFilter: MaybeRef<EventFilter> = ref<EventFilter>({}),
  query: MaybeRef<string> = ref<string>(''),
  pauseQuery: MaybeRef<boolean> = false,
  pageSize: MaybeRef<number> = ref<number>(10)
) => {
  const filter = computed(() => ({
    and: [toValue(baseFilter), toValue(userFilter)],
  }));

  const { fetching, data, error, pause, resume } = useQuery<
    AddressbaseSearchAutosuggestQuery,
    AddressbaseSearchAutosuggestQueryVariables
  >({
    query: AddressbaseSearchAutosuggestDocument,
    variables: {
      language: locale,
      filter: filter,
      query: query,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      pageSize: toValue(pageSize),
    },
    pause: pauseQuery,
  });

  const pois: Ref<RawAddressbaseSearchAutosuggestItemFragment[]> = computed(
    () => {
      return data.value?.pois?.nodes ?? [];
    }
  );

  return { fetching, error, data, pois, pause, resume };
};
